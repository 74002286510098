@import "variables";
//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";


.slogan-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.5rem;

    .slogan-wrapper {
        position: relative;
        margin: 0 auto;
        padding-left: 6rem;
        width: 20.25rem;
        line-height: 1.08;
        text-transform: uppercase;

        .slogan {
            display: block;
            font-size: $font-size-big-slogan;
            font-weight: $font-weight-medium;
            line-height: 1;
        }

        .slogan-title {
            display: block;
            position: absolute;
            bottom: 3.45rem;
            left: 0;
            max-width: 5.5rem;
            text-align: right;
            font-size: $font-size-slogan-title;
            color: $secondary;

            &:first-line {
                font-weight: $font-weight-medium;
            }
        }

        .slogan-subtitle {
            display: block;
            margin: 0.5rem 0 0;
            white-space: nowrap;
            text-align: left;
            font-size: $font-size-slogan-subtitle;
        }

        .icon-plus {
            stroke-width: 4px;
            position: relative;
            left: -2.25rem;
            bottom: 0.85rem;
            font-size: 1.5rem;
        }
    }
}

@media (min-width: 400px) {
    .slogan-container {
        .slogan-wrapper {
            .slogan {
                font-size: $font-size-big-slogan-sm;
            }

            .slogan-subtitle {
                text-align: center;
                font-size: $font-size-slogan-subtitle-sm;
            }
        }
    }
}


@include media-breakpoint-up(md) {
    .slogan-container {
        .slogan-wrapper {
            padding-left: 7rem;
            width: 25rem;

            .slogan {
                font-size: $font-size-big-slogan-md;
            }

            .slogan-title {
                max-width: 6.5rem;
                font-size: $font-size-slogan-title-md;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .slogan-container {
        .slogan-wrapper {
            width: 27rem;

            .slogan {
                font-size: $font-size-big-slogan-lg;
            }

            .slogan-title {
                bottom: 3.65rem;
            }

            .slogan-subtitle {
                font-size: $font-size-slogan-subtitle-lg;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .slogan-container {
        .slogan-wrapper {
            padding-left: 9rem;
            width: auto;

            .slogan {
                font-size: $font-size-big-slogan-xl;
            }

            .slogan-title {
                bottom: 3.95rem;
                max-width: 8.5rem;
                font-size: $font-size-slogan-title-xl;
            }

            .slogan-subtitle {
                font-size: $font-size-slogan-subtitle-xl;
            }

            .icon-plus {
                left: -1.5rem;
                bottom: 1rem;
                font-size: 1.75rem;
            }
        }
    }
}

@media (min-width: 1400px) {
    .slogan-container {
        .slogan-wrapper {
            .slogan {
                font-size: $font-size-big-slogan-xxl;
            }

            .slogan-title {
                bottom: 4.15rem;
            }
        }
    }
}
